import API from "./api";

export const SENDING_ENQUIRY = 'SENDING_ENQUIRY';
export const SENT_ENQUIRY = 'SENT_ENQUIRY';

const sendingEnquiry = () => {
	return {
		type: SENDING_ENQUIRY,
	}
};

const sentEnquiry = (response) => {
	return {
		type: SENT_ENQUIRY,
		response
	}
};


function sendEnquiry(formData) {
	return async (dispatch, getState, api) => {
		const dataAPI = new API(api);

		dispatch(sendingEnquiry());

		const json = await dataAPI.sendEnquiry(
			formData.name,
			formData.company,
			formData.industry,
			formData.email,
			formData.phone,
			formData.comments,
			formData.products
		);

		dispatch(sentEnquiry(json.data));
	}
}

export { sendEnquiry };