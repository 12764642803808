import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CSSModules from 'react-css-modules';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Row
} from 'reactstrap';

import styles from './styles.scss';
import { getMakes, getModels } from '../../data/machinery/actions';
import { sendEnquiry } from '../../data/enquiries/actions';
import { setResults } from '../../data/results/actions';
import Results from './components/Results';
import Navigation from './components/Navigation';
import Compare from './components/Compare';
import Summary from './components/Summary';
import Enquire from './components/Enquire';
import logo from '../sigma-logo.svg';
import Footer from '../../components/Footer';

class ResultsPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			productImages: false,
			currentView: 'all',
			selectedProducts: {
				packagedUnits: false,
				evaporators: false,
				condensers: false,
				pressurisers: false,
				compressors: false
			}
		}

		this.setCurrentView = this._setCurrentView.bind(this);
		this.setSelectedProduct = this._setSelectedProduct.bind(this);
	}

	_setSelectedProduct(type, product) {
		const { selectedProducts } = this.state;

		if (selectedProducts[type] && selectedProducts[type].id === product.id) {
			selectedProducts[type] = false;
		} else {
			selectedProducts[type] = product;
		}

		if (type === 'packagedUnits') {
			selectedProducts['evaporators'] = false;
			selectedProducts['condensers'] = false;
		}

		this.setState(selectedProducts);
	}

	componentDidMount() {
		const queryValues = queryString.parse(this.props.location.search);

		// If results page accessed directly, use query params to get required data
		if (!this.props.results.make) {
			this.props.getModels(queryValues.make, queryValues.product).then(() => {
					const selectedModel = this.props.models.data.find(model => {
						return model['model_id'] === queryValues.model
					});

					if (selectedModel) {
						this.props.setResults(selectedModel);
					}
				}
			);
		}
	}

	_setCurrentView(view) {
		this.setState({
			currentView: view
		});
	}


	renderFooter() {
		const { currentView } = this.state;
		const {makeVerbose, machineTypeVerbose, modelId} = this.props.results;

		if (currentView === 'all') {
				return <Footer showStartAgain={true} />
		} else if (currentView !== 'all' && currentView !== 'summary' && currentView !== 'enquire') {
			return <Footer
				showStartAgain={true} 
				showBack={true} 
				goBack={() => this.setCurrentView('all')}
				showNext={true} 
				goNext={() => this.setCurrentView('summary')}
				selectedProducts={false}
				currentMake={false}
				currentType={false}
				currentModel={false}
			/>

		} else if (currentView === 'summary') {
			return <Footer
				showStartAgain={true} 
				showBack={true} 
				goBack={() => this.setCurrentView('packagedUnits')}
				showNext={true} 
				goNext={() => this.setCurrentView('enquire')}
				showPrint={true}
				selectedProducts={this.state.selectedProducts}
				currentMake={makeVerbose}
				currentType={machineTypeVerbose}
				currentModel={modelId}
			/>
		} else if (currentView === 'enquire') {
			return <Footer
				showStartAgain={true} 
				showBack={true} 
				goBack={() => this.setCurrentView('summary')}
				selectedProducts={false}
				currentMake={false}
				currentType={false}
				currentModel={false}
			/>
		} else {
			return null;
		}
	}

	render() {
		const {makeVerbose, machineTypeVerbose, modelId} = this.props.results;

		return (
			<Fragment>
				<div styleName="results-header">
					<div styleName="results-header__brand">
						<img src={logo} alt="Sigma" />
					</div>
					<div styleName="results-header__title">
						{`${makeVerbose}/${machineTypeVerbose}/${modelId}`}
					</div>
					<div styleName="results-header__nav">
						{this.state.currentView !== 'all' &&
							<Navigation
							results={this.props.results}
							setView={this.setCurrentView}
							currentView={this.state.currentView}
							packageUnitSelected={this.state.selectedProducts.packagedUnits}
						/>
						}
						{this.state.currentView === 'all' && 
							<div styleName="results-header__info">
								<FontAwesomeIcon icon={['fas', 'info-circle']} />
								<p>
									CLICK ON THE PRODUCT TAB FOR FULL SPECIFICATION AND THEN SELECT THE MODEL 
									THAT SUITS YOUR NEEDS 
								</p>
							</div>
						}
					</div>
				</div>
				<div styleName="results-body">
					{this.state.currentView === 'all' &&
						<Results
							results={this.props.results}
							setView={this.setCurrentView}
						/>
					}
					{
						this.state.currentView !== 'all' && this.state.currentView !== 'summary' && this.state.currentView !== 'enquire' &&
						<Compare
							products={this.props.results[this.state.currentView]}
							type={this.state.currentView}
							selectedProducts={this.state.selectedProducts}
							setSelectedProduct={this.setSelectedProduct}
							setView={this.setCurrentView}
						/>
					}
					{
						this.state.currentView === 'summary' &&
						<Summary
							selectedProducts={this.state.selectedProducts}
							setView={this.setCurrentView}
							setSelectedProduct={this.setSelectedProduct}
						/>
					}
					{
						this.state.currentView === 'enquire' &&
						<Enquire
							selectedProducts={this.state.selectedProducts}
							setView={this.setCurrentView}
							setSelectedProduct={this.setSelectedProduct}
							sendEnquiry={this.props.sendEnquiry}
							enquiry={this.props.enquiry}
						/>
					}
				</div>
				{this.renderFooter()}
			</Fragment>
		)
	}
}

const mapStateToProps = state => {
	const makes = state.data.machinery.makes;
	const models = state.data.machinery.models;
	const results = state.data.results;
	const enquiry = state.data.enquiry

	return {
		makes,
		models,
		results,
		enquiry
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getMakes: () => dispatch(getMakes()),
		getModels: (make, product) => dispatch(getModels(make, product)),
		setResults: (selectedModel) => dispatch(setResults(selectedModel)),
		sendEnquiry: (formData) => dispatch(sendEnquiry(formData)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CSSModules(ResultsPage, styles));
