import React, {Fragment, useContext} from 'react';
import CSSModules from 'react-css-modules';
import Media from 'react-media';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Slider from "react-slick";

import styles from './styles.scss';
import { SiteContext } from '../../../../siteContext';
import ProductsList from '../../../../components/ProductsList';


const Results = (props) => {
	const { basePath } =  useContext(SiteContext);

	const sliderSettings = {
		centerMode: true,
		centerPadding: '30px',
		arrows: false,
		slidesToShow: 3,
		responsive: [
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 575,
				settings: {
					slidesToShow: 1,
				}
			}
		]
	}

	const products = (carousel = false) => {
		if (props.results.make) {
			if (carousel) { 
				return (
					<Slider {...sliderSettings}>
						<ProductsList setView={props.setView} slug="packagedUnits" productType="Packaged Units" products={props.results['packagedUnits']} />
					 	<ProductsList setView={props.setView} slug="evaporators" productType="Evaporators" products={props.results['evaporators']} />
						<ProductsList setView={props.setView} slug="condensers" productType="Condensers" products={props.results['condensers']} />
						<ProductsList setView={props.setView} slug="pressurisers" productType="Pressurisers" products={props.results['pressurisers']} />
						<ProductsList setView={props.setView} slug="compressors" productType="Compressors" products={props.results['compressors']} />
					</Slider>
				)
			} else {
				return (
					<Fragment>
						<ProductsList setView={props.setView} slug="packagedUnits" productType="Packaged Units" products={props.results['packagedUnits']} />
						<ProductsList setView={props.setView} slug="evaporators" productType="Evaporators" products={props.results['evaporators']} />
						<ProductsList setView={props.setView} slug="condensers" productType="Condensers" products={props.results['condensers']} />
						<ProductsList setView={props.setView} slug="pressurisers" productType="Pressurisers" products={props.results['pressurisers']} />
						<ProductsList setView={props.setView} slug="compressors" productType="Compressors" products={props.results['compressors']} />
					</Fragment>
				);
			}
		} else {
			return null;
		}
	}

	return (
		<Fragment>
			 <Media queries={{
				large: "(min-width: 992px)"
			}}>
			{matches => (
				<Fragment>
					{matches.large &&
						<div className={props.styles['results']}>
							{products()}
						</div>
					}
					{
						!matches.large &&
						<Fragment>
							<p styleName="mobile-only">Swipe left and right to view other Categories</p>
							{products(true)}
						</Fragment>
					}
				</Fragment>
			)}
			</Media>
		</Fragment>
	);
};

export default CSSModules(Results, styles);