import React, {useContext, Fragment} from 'react';
import CSSModules from 'react-css-modules';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SiteContext } from '../../siteContext';
import Button from '../Button';
import styles from './styles.scss';

const Footer = (props) => {
	const { basePath } =  useContext(SiteContext);

	const getPrintUrl = () => {
		const { selectedProducts, currentMake, currentType, currentModel } = props;
		const baseUrl = 'http://sigmacms.xwu8itckfe.ap-southeast-2.elasticbeanstalk.com/print/';

		if (selectedProducts) {
			const productIds = [];
			let productsString = '';

			if (selectedProducts.packagedUnits) {
				productIds.push(selectedProducts.packagedUnits.id);
			}

			if (selectedProducts.evaporators) {
				productIds.push(selectedProducts.evaporators.id);
			}

			if (selectedProducts.condensers) {
				productIds.push(selectedProducts.condensers.id);
			}

			if (selectedProducts.pressurisers) {
				productIds.push(selectedProducts.pressurisers.id);
			}

			if (selectedProducts.compressors) {
				productIds.push(selectedProducts.compressors.id);
			}

			productIds.forEach( (productId, index) => {
				productsString += `product=${productId}`;

				if (index != productIds.length - 1) {
					productsString += '&';
				}
			});

			const url = `${baseUrl}?${productsString}&make=${currentMake}&type=${currentType}&model=${currentModel}`;

			return url;
		}
	}
	
	return (
		<div styleName="footer">
			<div styleName="footer__left">
				{props.showStartAgain && 
					<Button link={basePath} style={{backgroundColor: '#005397'}}>
						<b>Start Again</b>
					</Button>
				}
				{props.showBack && 
					<Button onClick={props.goBack} style={{backgroundColor: '#0086ca', marginLeft: '5px'}}>
						<FontAwesomeIcon icon={['far', 'long-arrow-left']} /> Back
					</Button>
				}
			</div>
			<div styleName="footer__right">
				{props.showPrint &&
					<a
					href={getPrintUrl()}
					className="btn"
					target="_blank"
					style={{backgroundColor: '#0086ca', marginRight: '5px', borderRadius: '10px', color: 'white'}}
					>
						<FontAwesomeIcon icon={['far', 'print']} /> Print
					</a>
				}
				{props.showNext && 
					<Button onClick={props.goNext} style={{backgroundColor: '#0086ca'}}>
						Next <FontAwesomeIcon icon={['far', 'long-arrow-right']} /> 
					</Button>
				}
			</div>
		</div>
	);
}

export default CSSModules(Footer, styles);