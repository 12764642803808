import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faFacebook,
	faInstagram,
	faLinkedin,
} from '@fortawesome/free-brands-svg-icons';

import {
	faInfoCircle,
	faCircle,
} from '@fortawesome/pro-solid-svg-icons';

import {
	faCheck,
	faLongArrowRight,
	faLongArrowLeft,
	faExclamationTriangle,
	faPrint,
	faSpinner,
} from '@fortawesome/pro-regular-svg-icons';

// import {} from '@fortawesome/pro-light-svg-icons';


export default library.add(
    faFacebook,
	faInstagram,
	faLinkedin,
	faCheck,
	faLongArrowRight,
	faLongArrowLeft,
	faExclamationTriangle,
	faInfoCircle,
	faPrint,
	faSpinner
);
