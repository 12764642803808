import React from 'react';
import CSSModules from 'react-css-modules';
import classNames from 'classnames';

import styles from './styles.scss';

const Navigation = (props) => {
	const {results} = props;

	const packagedUnitsClass = classNames({
		active: props.currentView === 'packagedUnits',
		disabled: results['packagedUnits'].length === 0
	});

	const evaporatorClass = classNames({
		active: props.currentView === 'evaporators',
		disabled: props.packageUnitSelected || results['evaporators'].length === 0
	});

	const condenserClass = classNames({
		active: props.currentView === 'condensers',
		disabled: props.packageUnitSelected || results['condensers'].length === 0
	});

	const pressurisersClass = classNames({
		active: props.currentView === 'pressurisers',
		disabled: results['pressurisers'].length === 0
	});

	const compressorsClass = classNames({
		active: props.currentView === 'compressors',
		disabled: results['compressors'].length === 0
	});

	const disabledOnClick = (view) => {
		if (!props.packageUnitSelected) {
			props.setView(view);
		}
	}

	return (
		<ul styleName="navigation-list">
			<li
				styleName="navigation-item"
				className={packagedUnitsClass}
				onClick={() => props.setView('packagedUnits')}
			>
				<div styleName="navigation-item__label"> <span styleName="counter">1</span> Packaged Units</div>
			</li>
			<li
				styleName="navigation-item"
				className={evaporatorClass}
				onClick={() => disabledOnClick('evaporators')}
			>
				<div styleName="navigation-item__label"> <span styleName="counter">2</span> Evaporators</div>
			</li>
			<li
				styleName="navigation-item"
				className={condenserClass}
				onClick={() => disabledOnClick('condensers')}
			>
				<div styleName="navigation-item__label"> <span styleName="counter">3</span> Condensers</div>
			</li>
			<li
				styleName="navigation-item"
				className={pressurisersClass}
				onClick={() => props.setView('pressurisers')}
			>
				<div styleName="navigation-item__label"> <span styleName="counter">4</span> Pressurisers</div>
			</li>
			<li
				styleName="navigation-item"
				className={compressorsClass}
				onClick={() => props.setView('compressors')}
			>
				<div styleName="navigation-item__label"> <span styleName="counter">5</span> Compressors</div>
			</li>
		</ul>
	)
};

export default CSSModules(Navigation, styles);