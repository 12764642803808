import {
	SENDING_ENQUIRY,
	SENT_ENQUIRY
} from './actions';


const initialState = {
	sending: false,
	response: {}
};

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SENDING_ENQUIRY:
			return Object.assign({}, state, {
				sending: true,
				response: {}
			});
		case SENT_ENQUIRY:
			return Object.assign({}, state, {
				sending: false,
				response: action.response
			});
		default:
			return state;
	}
}