export const SET_RESULTS = 'SET_RESULTS';
export const CLEAR_RESULTS = 'CLEAR_RESULTS';

export const setResults = (selectedModel) => ({
	type: SET_RESULTS,
	make: selectedModel.make,
	makeVerbose: selectedModel['make_verbose'],
	machineType: selectedModel.type,
	machineTypeVerbose: selectedModel['type_verbose'],
	modelId: selectedModel['model_id'],
	packagedUnits: selectedModel['packaged_units'],
	evaporators: selectedModel.evaporators,
	condensers: selectedModel.condensers,
	pressurisers: selectedModel.pressurisers,
	compressors: selectedModel.compressors
});

export const clearResults = () => ({
	type: CLEAR_RESULTS
});