import API from "./api";

export const REQUESTING_MAKES = 'REQUESTING_MAKES';
export const RECEIVED_MAKES = 'RECEIVED_MAKES';

export const REQUESTING_MODELS = 'REQUESTING_MODELS';
export const RECEIVED_MODELS = 'RECEIVED_MODELS';


const requestingMakes = () => {
	return {
		type: REQUESTING_MAKES,
	}
};

const receiveMakes = (makes) => {
	return {
		type: RECEIVED_MAKES,
		makes
	}
}

const requestingModels = () => {
	return {
		type: REQUESTING_MODELS,
	}
};

const receiveModels = (models) => {
	return {
		type: RECEIVED_MODELS,
		models
	}
}

function getMakes() {
	return async (dispatch, getState, api) => {
		const dataAPI = new API(api);

		dispatch(requestingMakes());

		const json = await dataAPI.getMakes();

		dispatch(receiveMakes(json.data));
	}
}

function getModels(make, product) {
	return async (dispatch, getState, api) => {
		const dataAPI = new API(api);

		dispatch(requestingModels());

		const json = await dataAPI.getModels(make, product);

		dispatch(receiveModels(json.data));
	}
}

export { getMakes, getModels };