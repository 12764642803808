import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import CSSModules from 'react-css-modules';

import styles from './styles.scss';

const Button = (props) => {
	return (
		<Fragment>
			{props.link !== false
				? <Link styleName="button" style={props.style} to={props.link}>{props.children}</Link>
				: <a styleName="button" style={props.style} onClick={props.onClick}>{props.children}</a>
			}
		</Fragment>
	);
};

Button.defaultProps = {
	link: false
}

export default CSSModules(Button, styles);