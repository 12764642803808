import React, {useContext, useState} from 'react';
import CSSModules from 'react-css-modules';
import { Switch, Route } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';

import styles from './styles.scss';
import SearchPage from '../SearchPage';
import ResultsPage from '../ResultsPage';
import {SiteContext} from '../../siteContext';

const Base = (props) => {
	const { basePath } =  useContext(SiteContext);

	const {currentView, setCurrentView} = useState('search');

	return (
		<Container styleName="app-container" fluid={true}>
			<Row>
				<Switch>
					<Route path={`${basePath}/`} component={SearchPage} exact/>
					<Route path={`${basePath}/results`} component={ResultsPage} />
				</Switch>
			</Row>
		</Container>
	)
};

export default withRouter(CSSModules(Base, styles));