import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import CSSModules from 'react-css-modules';
import {
	Row
} from 'reactstrap';
import moment from 'moment';

import styles from './styles.scss';
import logo from '../sigma-logo.svg';
import { getMakes, getModels } from '../../data/machinery/actions';
import { setResults } from '../../data/results/actions';
import { SiteContext } from '../../siteContext';


const capitalize = (string) => {
	return string.replace(/^\w/, c => c.toUpperCase());
} 


class SearchPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedMake: 'default',
			selectedProduct: 'default',
			selectedModel: 'default',
		}

		this.onMakeSelect = this._onMakeSelect.bind(this);
		this.onProductSelect = this._onProductSelect.bind(this);
		this.onModelSelect = this._onModelSelect.bind(this);

	}

	componentDidMount() {
		this.props.getMakes();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.selectedMake !== prevState.selectedMake 
			|| this.state.selectedProduct !== prevState.selectedProduct ) {

			if (this.state.selectedMake !== 'default' && this.state.selectedProduct !== 'default') {
				this.props.getModels(this.state.selectedMake, this.state.selectedProduct);
			}
		}

		if (this.state.selectedModel !== prevState.selectedModel) {
			const {selectedMake, selectedProduct, selectedModel} = this.state;
			const { basePath } = this.context;

			this.props.history.push(
				`${basePath}/results/?make=${selectedMake}&product=${selectedProduct}&model=${selectedModel}`
			);
		}
	}

	_onMakeSelect(event) {
		this.setState({
			selectedMake: event.target.value,
			selectedProduct: 'default',
			selectedModel: 'default'
		});
	}

	_onProductSelect(event) {
		this.setState({
			selectedProduct: event.target.value,
		});
	}

	_onModelSelect(event) {
		this.setState({
			selectedModel: event.target.value
		});

		const selectedModel = this.props.models.data.find(model => {
			return model['model_id'] === event.target.value;
		});

		if (selectedModel) {
			this.props.setResults(selectedModel);
		}
	}

	renderMakeOptions() {
		return this.props.makes.data.map(make => {
			return <option key={make.id} value={make.id}>{capitalize(make.name)}</option>
		});
	}

	renderProductOptions() {
		if (this.state.selectedMake !== 'default') {
			const selectedMake = this.props.makes.data.find(make => {
				return parseInt(make.id) === parseInt(this.state.selectedMake)
			});

			if (selectedMake && Array.isArray(selectedMake.types)) {
				return selectedMake.types.map(type => {
					return <option key={type.id} value={type.id}>{capitalize(type.type)}</option>
				});
			}
		}
	}

	renderModelOptions() {
		return this.props.models.data.map(model => {
			return <option key={model['model_id']} value={model['model_id']}>{model['model_id']}</option>
		});
	}

	render() {
		return (
				<Fragment>
					<div styleName="product-search">
						<h3 className="text-center">Product Search</h3>
						<small styleName="info-text">Enter details to source relevant SIGMA HVAC products</small>
						<form styleName="search-form">
							<div className="form-group">
								<label htmlFor="make">Make</label>
								<select
								id="make"
								styleName="custom-select"
								disabled={this.props.makes.loaded ? false : true}
								onChange={this.onMakeSelect}
								value={this.state.selectedMake}
								>
									<option value='default' disabled>Choose Make</option>
									{this.renderMakeOptions()}
								</select>
							</div>
							<div className="form-group">
								<label htmlFor="product">Product</label>
								<select
									id="product"
									styleName="custom-select"
									disabled={this.state.selectedMake !== 'default' ? false : true}
									onChange={this.onProductSelect}
									value={this.state.selectedProduct}
								>
									<option value='default' disabled>Choose Product</option>
									{this.renderProductOptions()}
								</select>
							</div>
							<div className="form-group">
								<label htmlFor="model">Model</label>
								<select
								id="model"
								styleName="custom-select"
								disabled={this.state.selectedProduct !== 'default' ? false : true}
								onChange={this.onModelSelect}
								value={this.state.selectedModel}
								>
									<option value='default' disabled>Choose Model</option>
									{this.renderModelOptions()}
								</select>
							</div>
						</form>
					</div>
					<div styleName="about">
						<img styleName="logo" src={logo} alt="Sigma Logo" />
						<p>
							This SIGMA HVAC product search tool is designed to help you quickly identify 
							packaged units, evaporators, condensers pressurisers and compressors that are fitted 
							or will fit your mining equipment. It also features available companion items when relevant.
						</p>
						<p>
							Comprehensive information is also available in the SIGMA spare parts catalogue. 
							Available upon request.
						</p>
					</div>
				</Fragment>
		);
	}
}

const mapStateToProps = state => {
	const makes = state.data.machinery.makes;
	const models = state.data.machinery.models;

	return {
		makes,
		models
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getMakes: () => dispatch(getMakes()),
		getModels: (make, product) => dispatch(getModels(make, product)),
		setResults: (selectedModel) => dispatch(setResults(selectedModel)),
	}
}

SearchPage.contextType = SiteContext;

export default connect(mapStateToProps, mapDispatchToProps)(CSSModules(SearchPage, styles));
