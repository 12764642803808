import React from 'react';
import CSSModules from 'react-css-modules';

import styles from './styles.scss';

const ProductsList = (props) => {
	const { productType, products, setView, slug } = props;
	const hasproducts = products.length > 0;

	const renderProducts = () => {
		return products.map((item) => {
			const className = item.note === 'Primary' ? 'primary' : '';
			return (
				<li styleName="products-list__item" className={className} key={item.product.id}>
					<h4 styleName="products-list__title">{item.product.product_id}</h4>
					<img styleName="products-list__image" src={item.product.image} alt={`Image ${item.product.id}`} />
				</li>
			)
		});
	};

	return (
		<div styleName="products-list">
			<div
			styleName={hasproducts ? 'button' : 'button-disabled'}
			onClick={hasproducts ? () => props.setView(slug) : undefined}>
				{productType}
			</div>
			{hasproducts ?
				<ul styleName="products-list__list">
					{renderProducts()}
				</ul>

				: <p className="text-center mt-2">No Options Available</p>
			}
		</div>
	);
}

export default CSSModules(ProductsList, styles);