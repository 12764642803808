import baseApi from '../api';


export default class machineryAPI extends baseApi {
	async getMakes() {
		const makes = await this.fetchFromAPI(
			'/makes',
			'get-makes',
			'Get Makes',
			'get'
		);

		return makes;
	}

	async getModels(make, product) {
		const models = await this.fetchFromAPI(
			`/models?make=${make}&type=${product}`,
			'get-makes',
			'Get Makes',
			'get'
		);

		return models;
	}
}