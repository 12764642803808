import { combineReducers } from 'redux';

import { reducer as machineryReducer } from './machinery/reducer';
import { reducer as resultsReducer } from './results/reducer';
import { reducer as enquiryReducer } from './enquiries/reducer';


export default combineReducers({
	machinery: machineryReducer,
	results: resultsReducer,
	enquiry: enquiryReducer
});

