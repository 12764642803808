import React from 'react';
import { Field } from 'formik';
import CSSModules from 'react-css-modules';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

import styles from './styles.scss';


const EnquiryForm = props => {
	const { errors, touched, enquiry } = props;
	return (
		<Form styleName="enquiry-form">
			{ enquiry.response.name && <p>Thanks for you enquiry, we will be in tocuh with you soon.</p> }
			<FormGroup>
				<Label className="sr-only" for="name">Name*</Label>
				<Field
					id="name"
					className="form-control"
					styleName="form-input"
					placeholder="Name*"
					name="name"
				/>
				{errors.name && touched.name ? (<div className="invalid-feedback">{errors.name}</div>) : null}
			</FormGroup>
			<FormGroup>
				<Label className="sr-only" for="company">Company*</Label>
				<Field
					id="company"
					className="form-control"
					styleName="form-input"
					placeholder="Company*"
					name="company"
				/>
				{errors.company && touched.company ? (<div className="invalid-feedback">{errors.company}</div>) : null}
			</FormGroup>
			<FormGroup>
				<Label className="sr-only" for="name">Industry*</Label>
				<Field
					id="industry"
					className="form-control"
					styleName="form-input"
					placeholder="Industry"
					name="industry"
				/>
				{errors.industry && touched.industry ? (<div className="invalid-feedback">{errors.industry}</div>) : null}
			</FormGroup>
			<FormGroup>
				<Label className="sr-only" for="name">Email*</Label>
				<Field
					id="email"
					className="form-control"
					styleName="form-input"
					placeholder="Email*"
					name="email"
				/>
				{errors.email && touched.email ? (<div className="invalid-feedback">{errors.email}</div>) : null}
			</FormGroup>
			<FormGroup>
				<Label className="sr-only" for="name">Phone Number*</Label>
				<Field
					id="phone"
					className="form-control"
					styleName="form-input"
					placeholder="Phone Number*"
					name="phone"
				/>
				{errors.phone && touched.phone ? (<div className="invalid-feedback">{errors.phone}</div>) : null}
			</FormGroup>
			<FormGroup>
				<Label className="sr-only" for="name">Comments</Label>
				<Field
					id="comments"
					className="form-control"
					styleName="form-input"
					placeholder="Comments*"
					name="comments"
					component="textarea"
				/>
				{errors.comments && touched.comments ? (<div className="invalid-feedback">{errors.comments}</div>) : null}
			</FormGroup>
		</Form>
	)
};

export default CSSModules(EnquiryForm, styles);