import React, { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import CSSModules from 'react-css-modules';
import Media from 'react-media';
import Slider from "react-slick";
import { Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './styles.scss';
import Product from '../../../../components/Product';
import { SiteContext } from '../../../../siteContext';
import Button from '../../../../components/Button';


const Compare = (props) => {
	const { basePath } =  useContext(SiteContext);

	const sliderSettings = {
		centerMode: true,
		centerPadding: '30px',
		arrows: false,
		slidesToShow: 2,
		responsive: [
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
				}
			},
			{
				breakpoint: 575,
				settings: {
					slidesToShow: 1,
				}
			}
		]
	};

	const renderProducts = (carousel = false) => {
		const products = props.products.map(item => {
			const selected = props.selectedProducts[props.type] && props.selectedProducts[props.type].id === item.product.id;

			return (
				<div className={props.styles['col']} key={item.product.id}>
					<Product
					product={item.product}
					note={item.note}
					image={item.product.image}
					selected={selected}
					setSelectedProduct={props.setSelectedProduct}
					type={props.type}
					/>
				</div>
			)
		});

		if (carousel) {
			return (
				<Slider {...sliderSettings}>
					{products}
				</Slider>
			);
		} else {
			return products;
		}
	};

	return (
		<Row styleName="wrapper">
			<Media queries={{
				large: "(min-width: 992px)"
			}}>
				{matches => (
					<Fragment>
						{matches.large &&
							<div className={props.styles['compare']}>
								{renderProducts()}
							</div>
						}
						{!matches.large &&
							<div style={{position:'relative', width: '100%'}}>
								<p className={props.styles['mobile-only']}>
									Swipe left and right to compare products
								</p>
								{renderProducts(true)}
							</div>
						}
					</Fragment>
				)}
			</Media>
			
		</Row>
	);
};

export default CSSModules(Compare, styles);