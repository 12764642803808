import {
	SET_RESULTS,
	CLEAR_RESULTS
} from './actions';

const initialState = {
	make: false,
	makeVerbose: '',
	machineType: '',
	machineTypeVerbose: '',
	modelId: '',
	packagedUnits: [],
	evaporators: [],
	condensers: [],
	pressurisers: [],
	compressors: []
};

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_RESULTS:
			return Object.assign({}, state, {
				make: action.make,
				makeVerbose: action.makeVerbose,
				machineType: action.machineType,
				machineTypeVerbose: action.machineTypeVerbose,
				modelId: action.modelId,
				packagedUnits: action.packagedUnits, 
				evaporators: action.evaporators,
				condensers: action.condensers,
				pressurisers: action.pressurisers,
				compressors: action.compressors
			});
		case CLEAR_RESULTS:
			return Object.assign({}, state, initialState);
		default:
			return state;
	}
}