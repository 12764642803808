import {
	REQUESTING_MAKES,
	RECEIVED_MAKES,
	REQUESTING_MODELS,
	RECEIVED_MODELS,
} from './actions';

const initialState = {
	makes: {
		loaded: false,
		data: []
	},
	models: {
		loaded: false,
		data: []
	}
};

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case REQUESTING_MAKES:
			return Object.assign({}, state, {
				...state,
				makes: {
					loaded: false,
					data: [],
				}
			});
		case RECEIVED_MAKES:
			return Object.assign({}, state, {
				...state,
				makes: {
					loaded: true,
					data: action.makes
				}
			});
		case REQUESTING_MODELS:
			return Object.assign({}, state, {
				...state,
				models: {
					loaded: false,
					data: [],
				}
			});
		case RECEIVED_MODELS:
			return Object.assign({}, state, {
				...state,
				models: {
					loaded: true,
					data: action.models
				}
			});
		default:
			return state;
	}
}