import baseApi from '../api';


export default class machineryAPI extends baseApi {
	async sendEnquiry(name, company, industry, email, phoneNumber, comments, products = []) {
		const data = {
			name,
			company,
			industry,
			email,
			phone_number: phoneNumber,
			comments,
			products
		};

		const response = await this.fetchFromAPI(
			'/enquiry/',
			'send-enquiry',
			'Send Enquiry',
			'post',
			data
		);

		return response
	} 
}