import React, { Fragment, useContext } from 'react';
import CSSModules from 'react-css-modules';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withFormik } from 'formik';
import * as Yup from "yup";
import { Row } from 'reactstrap';

import Button from '../../../../components/Button';
import Product from '../../../../components/Product';
import styles from './styles.scss';
import Form from '../Form';
import { SiteContext } from '../../../../siteContext';


const formEnhancer = withFormik({
	mapPropsToValues: props => {
		return {
			name: '',
			company: '',
			industry: '',
			email: '',
			phone: '',
			comments: '',
		}
	},

	validationSchema: Yup.object().shape({
		name: Yup.string().required('Please provide your name'),
		company: Yup.string().required('Please provide a company'),
		industry: Yup.string(),
		email: Yup.string().email('Invalid email').required('Please provide an email'),
		phone: Yup.string().required('Please provide a phone number'),
		comments: Yup.string()
	}),
	handleSubmit: (values, {props, setSubmitting}) => {
		const {packagedUnits, evaporators, condensers, pressurisers, compressors} = props.selectedProducts;
		const products = [];

		if (packagedUnits) {
			products.push(packagedUnits.id);
		}

		if (evaporators) {
			products.push(evaporators.id);
		}

		if (condensers) {
			products.push(condensers.id);
		}

		if (pressurisers) {
			products.push(pressurisers.id);
		}

		if (compressors) {
			products.push(compressors.id);
		}

		const data = values;
		data['products'] = products;

		props.sendEnquiry(data);
		
		setSubmitting(false);
	},
	displayName: 'Enquiry Form'
});

const Enquire = (props) => {
	const { selectedProducts, submitForm, enquiry } = props;
	const { basePath } =  useContext(SiteContext);
	
	return (
		<Row styleName="row">
			<div styleName="summary">
				<h5>Please contact me on the following:</h5>
				<dl styleName="selected-products">
					{ selectedProducts.packagedUnits &&
						<Fragment>
							<dt>Packaged Units</dt>
							<dd>{selectedProducts.packagedUnits.product_id}</dd>
						</Fragment>
					}
					{ selectedProducts.evaporators &&
						<Fragment>
							<dt>Evaporators</dt>
							<dd>{selectedProducts.evaporators.product_id}</dd>
						</Fragment>
					}
					{ selectedProducts.condensers &&
						<Fragment>
							<dt>condensers</dt>
							<dd>{selectedProducts.condensers.product_id}</dd>
						</Fragment>
					}
					{ selectedProducts.pressurisers &&
						<Fragment>
							<dt>Pressurisers</dt>
							<dd>{selectedProducts.pressurisers.product_id}</dd>
						</Fragment>
					}
					{ selectedProducts.compressors &&
						<Fragment>
							<dt>Compressors</dt>
							<dd>{selectedProducts.compressors.product_id}</dd>
						</Fragment>
					}
				</dl>
			</div>
			<div styleName="form">
				<Form {...props} />
				<Button onClick={() => submitForm()}>
					Send Request 
					{enquiry.sending 
						? <FontAwesomeIcon icon={['far', 'spinner']} spin />
						: <FontAwesomeIcon icon={['far', 'long-arrow-right']} />
					}
				</Button>
			</div>
		</Row>
	);
};

export default formEnhancer(CSSModules(Enquire, styles));
