import React, { Component } from 'react';
import { Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// Styles required for App
import './FontAwesome';
import './scss/vendor/bootstrap.scss';
import './scss/vendor/slick.scss';

import './scss/common/ahamo-reset.scss';

import Base from './pages/Base';


class App extends Component{
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Base/>
		)
	}
}

// const mapStateToProps = state => {
//     const user = state.data.currentUser;
//     const userPermissions = state.data.currentUser.permissions;

//     return {
//         userPermissions,
//         user
//     }
// }

// const mapDispatchToProps = dispatch => {
//     return {
//         loadCurrentUser: () => {
//             dispatch(getCurrentUser());
//         }
//     }
// }

//export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
export default withRouter(App)