import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import '@babel/polyfill';
import ReactDOM from 'react-dom';
import React from 'react';
import thunk from 'redux-thunk';
import axios from 'axios';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';

import reducers from './reducers';
import App from './App';
import {SiteContext} from './siteContext';

const axiosInstance = axios.create({
	baseURL: 'https://sigmacms.ahamo.com.au/api'
});

const middlewares = [
	thunk.withExtraArgument(axiosInstance),
];

// Log redux store to cosole during development
if (process.env.NODE_ENV === 'development') {
	const { createLogger } =  require('redux-logger');

	const loggerMiddleware = createLogger();

	middlewares.push(loggerMiddleware);
}


// Redux dev tools extension
const composeEnhancers =
	typeof window === 'object' &&
	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
		// Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
	}) : compose;


const storeEnhancer = composeEnhancers(
	applyMiddleware(...middlewares)
	// other store enhancers if any
);

const store = createStore(
	reducers,
	window.INITIAL_STATE,
	storeEnhancer
);

const rootElement = document.getElementById('root');
const basePath = rootElement.getAttribute('data-base-path');

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<ScrollToTop>
				<SiteContext.Provider value={{basePath}}>
					<App />
				</SiteContext.Provider>
			</ScrollToTop>
		</BrowserRouter>
	</Provider>
	, rootElement
);
