import React, {Fragment} from 'react';
import CSSModules from 'react-css-modules';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './styles.scss';


const Product = (props) => {
	const {product, note, image, type, selected, setSelectedProduct} = props;

	const onSelect = () => {
		setSelectedProduct(type, product);
	}

	const renderCompanionItems = () => {
		return product['companion_items'].map(item => {
			return (
				<tr key={item['part_number']}>
					<td>{item['part_number']}</td>
					<td>{item.description}</td>
				</tr>
			);
		});
	}

	const displayValue = (field, unit) => {
		//return field ? `${field} <div dangerouslySetInnerHTML=${unit} />` : '-';
		return (
			<Fragment>
				{field ? <Fragment>{field} <span dangerouslySetInnerHTML={{__html: unit}}/></Fragment> : '-'}
			</Fragment>
		);
	}

	return (
		<div styleName="product">
			<div styleName="product-header">
				<div>
					<h5 styleName="product-model">{product.product_id}</h5>
					<small styleName="product-description">{note}</small>
					<div styleName="product-selection">
						<div styleName="select-product" onClick={onSelect}>
							{ selected ? <FontAwesomeIcon icon={['far', 'check']} /> : '' }
						</div>
						<span> {selected ? 'Click to unselect' : 'Click to select'}</span>
					</div>
				</div>
				<div>
					<img styleName="product-image" src={image} alt={`Image ${product.id}`} />
				</div>
			</div>
			<div styleName="product-specifications">
				<h3 styleName="product-title">Specifications</h3>
				<table styleName="product-specs-table">
  					<thead>
  						<tr>
  							<th scope="col"></th>
  							<th scope="col">Metric</th>
  							<th scope="col">Imperial</th>
  						</tr>
  					</thead>
  					<tbody>
  						<tr>
  							<th scope="row">Cooling Capacity</th>
  							<td>{ displayValue(product['cooling_metric'], 'kW') }</td>
  							<td>{ displayValue(product['cooling_imperial'], 'BTU') }</td>
  						</tr>
  						<tr>
  							<th scope="row">Heating Capacity</th>
  							<td>{ displayValue(product['heating_metric'], 'kW') }</td>
  							<td>{ displayValue(product['heating_imperial'], 'BTU') }</td>
  						</tr>
  						<tr>
  							<th scope="row">Fan Speeds</th>
  							<td>{ displayValue(product['fan_speeds'], '') }</td>
  							<td>{ displayValue(product['fan_speeds'], '') }</td>
  						</tr>
  						<tr>
  							<th scope="row">Air Flow</th>
  							<td>{ displayValue(product['air_flow_metric'], 'l/s') }</td>
  							<td>{ displayValue(product['air_flow_imperial'], 'CFM') }</td>
  						</tr>
  						<tr>
  							<th scope="row">Fresh Air</th>
  							<td>{ displayValue(product['fresh_air_metric'], 'l/s') }</td>
  							<td>{ displayValue(product['fresh_air_imperial'], 'CFM') }</td>
  						</tr>
  						<tr>
  							<th scope="row">Power Supply</th>
  							<td>{ displayValue(product['power_supply'], '') }</td>
  							<td>{ displayValue(product['power_supply'], '') }</td>
  						</tr>
  						<tr>
  							<th scope="row">Current Draw</th>
  							<td>{ displayValue(product['current_draw'], '') }</td>
  							<td>{ displayValue(product['current_draw'], '') }</td>
  						</tr>
  						<tr>
  							<th scope="row">Refrigerant</th>
  							<td>{ displayValue(product['refrigerant'], '') }</td>
  							<td>{ displayValue(product['refrigerant'], '') }</td>
  						</tr>
  						<tr>
  							<th scope="row">Maximum Ambient Temperature</th>
  							<td>{ displayValue(product['max_ambient_temperature_metric'], '&#8451;') }</td>
  							<td>{ displayValue(product['max_ambient_temperature_imperial'], '&#8457;') }</td>
  						</tr>
  						<tr>
  							<th scope="row">Weight (nominal)</th>
  							<td>{ displayValue(product['weight_metric'], 'Kg') }</td>
  							<td>{ displayValue(product['weight_imperial'], 'lbs') }</td>
  						</tr>
  						<tr>
  							<th scope="row">Technical Manual Reference</th>
  							<td>{ displayValue(product['technical_manual_reference'], '')}</td>
  							<td>{ displayValue(product['technical_manual_reference'], '')}</td>
  						</tr>
  						<tr>
  							<th scope="row">Dimension<br/>(L X W X H)</th>
  							<td>{ displayValue(product['dimension_metric'], '') }</td>
  							<td>{ displayValue(product['dimension_imperial'], '')}</td>
  						</tr>
  					</tbody>
  				</table>
			</div>
			{ product['companion_items'].length > 0 &&
				<div styleName="product-specifications">
					<h3 styleName="product-title">Companion Items</h3>
					<table styleName="product-specs-table">
	  					<thead>
	  						<tr>
	  							<th scope="col">Part No.</th>
	  							<th scope="col">Description</th>
	  						</tr>
	  					</thead>
	  					<tbody>
	  						{renderCompanionItems()}
	  					</tbody>
	  				</table>
				</div>
			}
		</div>
	);
}

export default CSSModules(Product, styles);