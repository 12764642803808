import React, {useContext} from 'react';
import CSSModules from 'react-css-modules';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row } from 'reactstrap';

import Button from '../../../../components/Button';
import Product from '../../../../components/Product';
import { SiteContext } from '../../../../siteContext';
import styles from './styles.scss';


const Summary = (props) => {
	const { selectedProducts, setSelectedProduct } = props;

	const { basePath } =  useContext(SiteContext);

	const renderSelectedProducts = () => {
		const products = [];

		for ( let [type, product] of Object.entries(selectedProducts)) {
			if (product) {
				products.push({
					product,
					type,
					note: '',
					image: product.image,
					selected: true,
					setSelectedProduct: setSelectedProduct,
				});
			}
		}

		return products.map(item => {
			return (
				<div styleName="col" key={item.product.id}>
					<div styleName="product-header">
						{item.product.type}
					</div>
					<Product
						product={item.product}
						note={''}
						image={item.image}
						selected={item.selected}
						setSelectedProduct={item.setSelectedProduct}
						type={item.type}
					/>
				</div>
			)
		})
	};

	return (
		<Row styleName="wrapper">
			<div styleName="header">
				<h3 styleName="title">Product Selection Summary</h3>
			</div>
			<div styleName="body">
				{renderSelectedProducts()}
			</div>
		</Row>
	)
};

export default CSSModules(Summary, styles);
